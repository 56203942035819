import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/flop-app/Hero";
import Features from "../sections/flop-app/Features";
import FeaturePM from "../sections/flop-app/FeaturePM";
import Features2 from "../sections/flop-app/Features2";
import Kpi from "../sections/flop-app/Kpi";
import Divider from "../components/Flop/Divider";
import Seo from "../components/Seo/seo";

const FlopAppPage = () => {
  return (
    <>
      <PageWrapper headerDark>
        <Seo
          title="Flop Application"
          desc="The ultimate app for every poker player."
        />
        <Hero />
        <Features />
        <FeaturePM />
        <Features2 />
        {/* <Affiliate /> */}
        <Kpi />
        <Divider backgroundColor="#e9ebee" />
      </PageWrapper>
    </>
  );
};

export default FlopAppPage;
