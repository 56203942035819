import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import chatPng from "../../assets/image/flop/products/flop-app/chat.png";
import createPng from "../../assets/image/flop/products/flop-app/create.png";
import newsPng from "../../assets/image/flop/products/flop-app/news.png";
import sharePng from "../../assets/image/flop/products/flop-app/share.png";
import tablePng from "../../assets/image/flop/products/flop-app/table.png";
import usersPng from "../../assets/image/flop/products/flop-app/users.png";

const FeatureCard = ({ backgroundColor, icon, title, children, ...rest }) => (
  <Box pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      backgroundColor={`${backgroundColor}33`}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
    >
      <img src={icon} alt="Flop app icon" />
    </Box>
    <div className="text-center">
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    <Section className="position-relative">
      <Container>
        <Row className="mb-3 text-center">
          <Col lg="12">
            <Title>
              <FlopFormattedMessageWrapper id="products.flopapp.features.title" />
            </Title>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#36D1CF" icon={usersPng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.connect" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#FE5F55" icon={sharePng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.share" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#4D8DCC" icon={newsPng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.news" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#F18B2D" icon={tablePng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.join" />
            </FeatureCard>
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#BA57CE" icon={createPng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.create" />
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard backgroundColor="#8A9BA8" icon={chatPng}>
              <FlopFormattedMessageWrapper id="products.flopapp.features.chat" />
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
