import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";
import { device } from "../../utils";

import flopAppLogoLightPng from "../../assets/image/flop/flop-app-logo-light.png";
import androidIcon from "../../assets/image/flop/products/flop-app/icon-android.png";
import iosIcon from "../../assets/image/flop/products/flop-app/icon-ios.png";
import flopAppPreviewPng from "../../assets/image/flop/products/flop-app/preview.png";

const ImgRight = styled.img`
  max-width: 50%;
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="black" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={[5, 3, 5, 3, 0]}>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="8" sm="9" className="order-lg-2">
                <div className="text-center text-lg-right position-relative">
                  <div
                    className="img-main"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <ImgRight
                      src={flopAppPreviewPng}
                      alt="First land of poker application - The application all poker players should have"
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <img
                      className="mb-3"
                      src={flopAppLogoLightPng}
                      alt="Flop app logo light"
                    />
                    <Title color="light" variant="hero">
                      <FlopFormattedMessageWrapper id="products.flopapp.hero.title" />
                    </Title>
                    <Text color="light" mb={4}>
                      <FlopFormattedMessageWrapper id="products.flopapp.hero.subtitle" />
                    </Text>
                    <div className="d-flex flex-column align-items-start mb-3">
                      <a
                        href="https://flop.page.link/get-flop-app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button mb={3}>
                          <FlopFormattedMessageWrapper id="products.flopapp.hero.cta" />
                        </Button>
                      </a>
                    </div>
                    <Box>
                      <a
                        href="https://flop.page.link/get-flop-app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text fontWeight={200} fontSize={"17px"} color="light">
                          <FlopFormattedMessageWrapper id="products.flopapp.hero.download" />
                          <img
                            src={androidIcon}
                            className="mx-2"
                            alt="Icon for FLOP android download"
                          ></img>
                          &amp;
                          <img
                            src={iosIcon}
                            className="mx-2"
                            alt="Icon for FLOP iOs download"
                          ></img>
                        </Text>
                      </a>
                    </Box>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default Hero;
