import React from "react";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import flopAppPMPng from "../../assets/image/flop/products/flop-app/flop-pm.png";

const FeaturePM = () => {
  return (
    <>
      <Section bg="greyBg" className="position-relative">
        <Box px={[1, 1, 1, 5, 6]} pt={null}>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="8" sm="9" className="mb-5 mb-lg-0">
                <div className="text-center position-relative">
                  <div
                    className="img-main"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <img
                      alt="Flop PM icon"
                      className="img-fluid"
                      src={flopAppPMPng}
                    />
                  </div>
                </div>
              </Col>
              <Col lg="6" style={{paddingLeft: 20, paddingRight: 20}}>
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box>
                    <Title variant="hero">
                      <FlopFormattedMessageWrapper id="products.flopapp.featurepm.title" />
                    </Title>
                    <Text mb={4}>
                      <FlopFormattedMessageWrapper id="products.flopapp.featurepm.subtitle" />
                    </Text>
                  </Box>
                </div>
              </Col>
            </Row>
          </Col>
        </Box>
      </Section>
    </>
  );
};

export default FeaturePM;
