import React from "react";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

const Kpi = () => (
  <Section bg="greyBg">
    <Box px={[1, 1, 1, 5, 6]}>
      <Box className="text-center">
        <Title color="dark">
          <FlopFormattedMessageWrapper id="products.flopapp.kpi.title" />
        </Title>
        <Row className="pt-3">
          <Col md="6" className="mb-5">
            <Box>
              <Title color="#4D8DCC">12000+</Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopapp.kpi.kpi1" />
              </Text>
            </Box>
          </Col>
          <Col md="6" className="mb-5">
            <Box>
              <Title color="#4D8DCC">6000+</Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopapp.kpi.kpi2" />
              </Text>
            </Box>
          </Col>
          <Col md="6" className="mb-5">
            <Box>
              <Title color="#4D8DCC">3000+</Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopapp.kpi.kpi3" />
              </Text>
            </Box>
          </Col>
          <Col md="6" className="mb-5">
            <Box>
              <Title color="#4D8DCC">3000+</Title>
              <Text>
                <FlopFormattedMessageWrapper id="products.flopapp.kpi.kpi4" />
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>
    </Box>
  </Section>
);

export default Kpi;
